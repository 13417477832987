import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Title from '@lekoarts/gatsby-theme-minimal-blog/src/components/title';
import FeaturedProjectsListItem from '../components/FeaturedProjectsListItem.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* import List from '@lekoarts/gatsby-theme-minimal-blog/src/components/list' */
    }
    {
      /* import ProjectSummary from '../components/ProjectSummary.tsx' */
    }


    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I`}{`'`}{`m Joe Astuccio. Web developer, sailor, stargazer, hockey player, and all around fun guy... but my absolute favorite thing is being a dad.`}</p>
    <p>{`I build interesting things using React, Gatsby, Svelte, JavaScript, HTML, CSS, Styled Components, SCSS, and more. Thank you for visiting.`}</p>
    <Title text="Projects" mdxType="Title" />
    <FeaturedProjectsListItem href="https://jva-eatery.netlify.com/" title="Eatery" description="A restaurant website" imgPath="https://res.cloudinary.com/jastuccio/image/upload/v1577152945/sites/eatery.png" mdxType="FeaturedProjectsListItem" />
    {
      /* <Projects /> */
    }
    {
      /* <ProjectSummary
       name='Eatery Cafe'
       desc='A restaurant site built using Gatsby JS'
       href='https://jva-eatery.netlify.com/'
      /> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      